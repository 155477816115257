<template>
	<div>
		<el-dialog :draggable="true" width="85%" top="5vh" v-model="outerVisible" @close="Reset" :title="title">
			<div class="dialog">
				<el-form ref="ruleForm" label-width="80px">
					<el-form-item label="排序">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.orderby" placeholder="请输入排序"></el-input>
					</el-form-item>
					<el-form-item label="名称">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.name" placeholder="请输入名称"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<!-- 图文详情 -->
			<content ref="content" @Submitinput="Submitinput">
			</content>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">取消</el-button>
					<el-button size="mini" class="dialog-btn el-white" type="primary" @click="submitForm">确定
					</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/entry";
	import content from '@/components/content.vue'
	export default {
		components: {
			content
		},
		data() {
			return {
				outerVisible: false,
				title: '新增',
				list: [],
				ruleForm: {
					name: '',
					orderby: 0,
					party_member_id: '',
					content: '',
				}
			}
		},
		methods: {
			// 图文详情
			Submitinput(e) {
				this.ruleForm.content = e
			},
			// 获取信息
			getinfo() {
				this.$http.post(api.info, {
						id: this.ruleForm.id
					})
					.then((res) => {
						if (res.code == 200) {
							this.ruleForm = res.data
							// 赋值
							this.$refs.content.setContent(res.data.content)
						}
					})
			},
			// 显示隐藏
			show(val, id,party_member_id) {
				this.ruleForm.party_member_id = party_member_id
				this.title = val
				if (id) {
					this.ruleForm.id = id
					this.getinfo()
				}
				this.outerVisible = !this.outerVisible
			},
			// 清空数据
			Reset() {
				for (let key in this.ruleForm) {
					this.ruleForm[key] = ''
				}
				this.$refs.content.emptyContent()
			},
			//新增/编辑
			submitForm() {
				if (this.ruleForm.name === '') {
					ElMessage({
						message: '请输入名称',
						type: 'warning',
					})
				} else {
					if (this.title == '新增') {
						this.add()
					} else {
						this.edit()
					}
				}
			},
			// 编辑
			edit() {
				this.$http.post(api.edit, this.ruleForm)
					.then((res) => {
						if (res.code == 200) {
							ElMessage({
								type: 'success',
								message: '编辑成功',
							})
							this.$emit('SubmitForm')
							this.outerVisible = false
						}
					})
			},
			// 新增
			add() {
				this.$http.post(api.add, this.ruleForm)
					.then((res) => {
						if (res.code == 200) {
							ElMessage({
								type: 'success',
								message: '新增成功',
							})
							this.$emit('SubmitForm')
							this.outerVisible = false
						}
					})
			}
		}
	}
</script>

<style scoped>
</style>