import request from './request'

const api = {
	// 获取列表
	lists: '/partymember/lists',
	// 添加
	add: '/partymember/add',
	// 删除
	dele: '/partymember/del',
	// 编辑
	edit: '/partymember/update',
	// 详情
	info: '/partymember/read',
	// 更新个人资料
	infoupdate: '/partymaterials/update',
	// 获取个人资料
	getread: '/partymaterials/read',
	// 通过党员申请
	agree: '/partymember/agree',
	// 拒绝
	refuse: '/partymember/refuse',
	// 村
	cunlist: '/village/lists',
	// 党费
	updatePartyFee: '/partymember/updatePartyFee',
}
export {
	request,
	api
}
