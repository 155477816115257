<template>
	<div>
		<el-dialog :draggable="true" width="85%" top="5vh" v-model="outerVisible" title="预备期思想汇报">
			<div class="dialog">
				<!-- 筛选 -->
				<div class="crud">
					<ul class="crud-list">
						<li>
							<el-button size="mini" @click="getList">刷新</el-button>
						</li>
						<li v-if="$store.state.villageId>0">
							<el-button @click="bindfrom('新增')" color="#00ad79" style="color: white" class="el-white"
								size="mini">新增
							</el-button>
						</li>
						<li v-if="$store.state.villageId>0">
							<el-button @click="alldele" class="el-white" size="mini" type="danger">删除</el-button>
						</li>
						<li>
							<el-input @clear="reset" type="text" clearable v-model="form.keyword" placeholder="请输入名称">
							</el-input>
						</li>
						<li>
							<el-button @click="search" color="#00ad79" class="el-white" style="color: white" size="mini">搜索
							</el-button>
						</li>
						<li>
							<el-button @click="reset" size="mini" class="el-white" type="danger">清空</el-button>
						</li>
					</ul>
				</div>
				<!-- 表格列表 -->
				<div class="table">
					<el-table element-loading-text="加载中..." v-loading="tableloading" :height="maxheight" border
						:data="tableData" @selection-change="handleSelectionChange">
						<el-table-column type="selection" width="80" />
						<el-table-column type="index" label="序号" width="80" />
						<el-table-column property="name" label="名称" />
						<el-table-column property="create_time" label="创建时间" />
						<el-table-column property="address" label="操作" v-if="$store.state.villageId>0">
							<template #default="scope">
								<el-button @click="bindfrom('编辑',scope.row.id)" size="mini">编辑</el-button>
								<el-button size="mini" @click="dele(scope.row.id)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
					<!--分页 -->
					<div class="pages">
						<el-pagination background v-model="form.page" :page-sizes="[15, 20, 30, 40,50,100]"
							:page-size="form.limit" layout="sizes, prev, pager, next, jumper" :total="total"
							@size-change="handleSizeChange" @current-change="handleCurrentChange">
						</el-pagination>
					</div>
				</div>
				<!-- 新增/编辑弹窗 -->
				<forms ref="form" @SubmitForm="getList"></forms>
			</div>

			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">关闭</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	import forms from './form.vue'
	import {
		request,
		api
	} from "@/api/thought";
	export default {
		components: {
			forms
		},
		data() {
			return {
				maxheight:'500px',
				outerVisible: false,
				total: 0,
				form: {
					keyword: '',
					page: 1,
					limit: 15,
					party_member_id: ""
				},
				tableData: [],
				idlist: [],
				mid:''
			}
		},
		methods: {
			// 显示隐藏
			show(id) {
				this.mid = id
				this.form.party_member_id = id
				this.getList()
				this.outerVisible = !this.outerVisible
			},
			handleSizeChange(e) {
				this.form.limit = e
				this.getList()
			},
			// 分页
			handleCurrentChange(e) {
				this.form.page = e
				this.getList()
			},
			// 清空
			reset() {
				for (let key in this.form) {
					this.form[key] = ''
				}
				this.form.limit = 15
				this.form.page = 1
				this.form.party_member_id = this.mid
				this.getList()
			},
			// 搜索
			search() {
				this.form.page = 1
				this.getList()
			},
			// 表格全选
			handleSelectionChange(e) {
				this.idlist = []
				e.forEach(item => {
					this.idlist.push(item.id)
				})
			},
			// 获取列表
			getList() {
				this.tableloading = true
				this.$http.post(api.lists, this.form)
					.then((res) => {
						if (res.code == 200) {
							this.tableData = res.data.data
							this.total = res.data.count
							this.tableloading = false
						}
					})
			},
			// 新增
			bindfrom(val, id) {
				this.$refs.form.show(val, id,this.form.party_member_id )
			},
			// 批量删除
			alldele() {
				if (this.idlist.length == 0) {
					ElMessage({
						message: '请选择需要删除数据',
						type: 'warning',
					})
				} else {
					ElMessageBox.confirm(
							'此操作将永久删除?',
							'提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning',
							}
						)
						.then(() => {
							this.$http.post(api.dele, {
									id: this.idlist.join(',')
								})
								.then((res) => {
									if (res.code == 200) {
										ElMessage({
											type: 'success',
											message: '删除成功',
										})
										this.form.page = 1
										this.getList()
									}
								})
						})
						.catch(() => {
							// 取消
						})
				}
			},
			// 删除
			dele(id) {
				ElMessageBox.confirm(
						'此操作将永久删除?',
						'提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
						}
					)
					.then(() => {
						this.$http.post(api.dele, {
								id: id
							})
							.then((res) => {
								if (res.code == 200) {
									ElMessage({
										type: 'success',
										message: '删除成功',
									})
									this.form.page = 1
									this.getList()
								}
							})
					})
					.catch(() => {
						// 取消
					})
			}
		}
	}
</script>

<style>
</style>