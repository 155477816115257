<template>
	<div>
		<div class="info-bg" :style="{height:maxheight}">
			<div class="dialog" element-loading-text="加载中..." v-loading="tableloading">
				<el-form ref="ruleForm" label-width="120px">
					<el-form-item label="入党申请表" @click="binType(0)">
						<span class="xx"></span>
						<div class="dialog-up">
							<el-upload multiple accept=".pdf,.doc,.docx,.xlm,.zip,.ppt,.xls,.xlsx,.rtf,.word"
								:headers="Headers" :action="ActionUrl" :on-success="UploadSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
							<div class="dialog-up-left">
								<div class="upload-li" v-if="list.length>0" v-for="(item,index) in list" :key="index">
									<div>{{item.OriginalName}}</div>
									<p @click="dele(index,0)">删除</p>
									<p @click="open(item.url)">下载</p>
								</div>
							</div>
						</div>
						<div style="float: right;" @click="$router.go(-1)">
							<el-button size="mini"><i class="icon iconfont icon-fanhui"></i>返回上一步</el-button>
						</div>
					</el-form-item>
					<el-form-item label="入党申请表图片" @click="binType(26)">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 预览图 -->
							<div class="imgList" v-if="list26.length>0" v-for="(item,index) in list26" :key="index">
								<el-image class="dialog-img" :src="item" :preview-src-list="list26"
									:initial-index="index" fit="fill">
								</el-image>
								<p class="dele" @click="dele(index,26)">删除</p>
							</div>
							<el-upload accept=".jpg,.png" :headers="Headers" :action="ActionUrl"
								:on-success="UploadSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
						</div>
					</el-form-item>

					<div class="title">支委会确定为入党积极分子的会议记录和公示</div>
					<el-form-item label="备注">
						<span class="xx"></span>
						<el-input type="textarea" clearable v-model="ruleForm.remark1" placeholder="请输入备注"></el-input>
					</el-form-item>
					<el-form-item label="图片" @click="binType(1)">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 预览图 -->
							<div class="imgList" v-if="list1.length>0" v-for="(item,index) in list1" :key="index">
								<el-image class="dialog-img" :src="item" :preview-src-list="list1"
									:initial-index="index" fit="fill">
								</el-image>
								<p class="dele" @click="dele(index,1)">删除</p>
							</div>
							<el-upload accept=".jpg,.png" :headers="Headers" :action="ActionUrl"
								:on-success="UploadSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
						</div>
					</el-form-item>
					<el-form-item label="附件" @click="binType(2)">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 文件 -->
							<el-upload multiple accept=".pdf,.doc,.docx,.xlm,.zip,.ppt,.xls,.xlsx,.rtf,.word"
								:headers="Headers" :action="ActionUrl" :on-success="UploadSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
							<div class="dialog-up-left">
								<div class="upload-li" v-if="list2.length>0" v-for="(item,index) in list2" :key="index">
									<div>{{item.OriginalName}}</div>
									<p @click="dele(index,2)">删除</p>
									<p @click="open(item.url)">下载</p>
								</div>
							</div>
						</div>
					</el-form-item>

					<div class="title">入党积极分子写实簿</div>
					<el-form-item label="备注">
						<span class="xx"></span>
						<el-input type="textarea" clearable v-model="ruleForm.remark2" placeholder="请输入备注"></el-input>
					</el-form-item>
					<el-form-item label="图片" @click="binType(3)">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 预览图 -->
							<div class="imgList" v-if="list3.length>0" v-for="(item,index) in list3" :key="index">
								<el-image class="dialog-img" :src="item" :preview-src-list="list3"
									:initial-index="index" fit="fill">
								</el-image>
								<p class="dele" @click="dele(index,3)">删除</p>
							</div>
							<el-upload accept=".jpg,.png" :headers="Headers" :action="ActionUrl"
								:on-success="UploadSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
						</div>
					</el-form-item>
					<el-form-item label="附件" @click="binType(4)">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 文件 -->
							<el-upload multiple accept=".pdf,.doc,.docx,.xlm,.zip,.ppt,.xls,.xlsx,.rtf,.word"
								:headers="Headers" :action="ActionUrl" :on-success="UploadSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
							<div class="dialog-up-left">
								<div class="upload-li" v-if="list4.length>0" v-for="(item,index) in list4" :key="index">
									<div>{{item.OriginalName}}</div>
									<p @click="dele(index,4)">删除</p>
									<p @click="open(item.url)">下载</p>
								</div>
							</div>
						</div>
					</el-form-item>

					<div class="title">入党积极分子思想汇报(每季度1篇)</div>
					<el-form-item label="录入">
						<span class="xx"></span>
						<div class="dialog-up" @click="entry(ruleForm.id)">
							<el-upload disabled>
								<div class="upload">
									<i class="icon iconfont icon-tianjia2"></i>
								</div>
							</el-upload>
						</div>
					</el-form-item>

					<div class="title">支委会确定为发展对象的会议记录和公示</div>
					<el-form-item label="备注">
						<span class="xx"></span>
						<el-input type="textarea" clearable v-model="ruleForm.remark3" placeholder="请输入备注"></el-input>
					</el-form-item>
					<el-form-item label="图片" @click="binType(5)">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 预览图 -->
							<div class="imgList" v-if="list5.length>0" v-for="(item,index) in list5" :key="index">
								<el-image class="dialog-img" :src="item" :preview-src-list="list5"
									:initial-index="index" fit="fill">
								</el-image>
								<p class="dele" @click="dele(index,5)">删除</p>
							</div>
							<el-upload accept=".jpg,.png" :headers="Headers" :action="ActionUrl"
								:on-success="UploadSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
						</div>
					</el-form-item>
					<el-form-item label="附件" @click="binType(6)">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 文件 -->
							<el-upload multiple accept=".pdf,.doc,.docx,.xlm,.zip,.ppt,.xls,.xlsx,.rtf,.word"
								:headers="Headers" :action="ActionUrl" :on-success="UploadSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
							<div class="dialog-up-left">
								<div class="upload-li" v-if="list6.length>0" v-for="(item,index) in list6" :key="index">
									<div>{{item.OriginalName}}</div>
									<p @click="dele(index,6)">删除</p>
									<p @click="open(item.url)">下载</p>
								</div>
							</div>
						</div>
					</el-form-item>

					<div class="title">政治审查相关材料(家庭政审材料、无犯罪证明、无违法计生证明)</div>
					<el-form-item label="家庭政审材料" @click="binType(7)">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 文件 -->
							<el-upload multiple accept=".pdf,.doc,.docx,.xlm,.zip,.ppt,.xls,.xlsx,.rtf,.word"
								:headers="Headers" :action="ActionUrl" :on-success="UploadSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
							<div class="dialog-up-left">
								<div class="upload-li" v-if="list7.length>0" v-for="(item,index) in list7" :key="index">
									<div>{{item.OriginalName}}</div>
									<p @click="dele(index,7)">删除</p>
									<p @click="open(item.url)">下载</p>
								</div>
							</div>
						</div>
					</el-form-item>
					<el-form-item label="无犯罪证明" @click="binType(8)">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 文件 -->
							<el-upload multiple accept=".pdf,.doc,.docx,.xlm,.zip,.ppt,.xls,.xlsx,.rtf,.word"
								:headers="Headers" :action="ActionUrl" :on-success="UploadSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
							<div class="dialog-up-left">
								<div class="upload-li" v-if="list8.length>0" v-for="(item,index) in list8" :key="index">
									<div>{{item.OriginalName}}</div>
									<p @click="dele(index,8)">删除</p>
									<p @click="open(item.url)">下载</p>
								</div>
							</div>
						</div>
					</el-form-item>
					<el-form-item label="无违法计生证明" @click="binType(9)">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 文件 -->
							<el-upload multiple accept=".pdf,.doc,.docx,.xlm,.zip,.ppt,.xls,.xlsx,.rtf,.word"
								:headers="Headers" :action="ActionUrl" :on-success="UploadSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
							<div class="dialog-up-left">
								<div class="upload-li" v-if="list9.length>0" v-for="(item,index) in list9" :key="index">
									<div>{{item.OriginalName}}</div>
									<p @click="dele(index,9)">删除</p>
									<p @click="open(item.url)">下载</p>
								</div>
							</div>
						</div>
					</el-form-item>

					<div class="title">发展对培训结业证书</div>
					<el-form-item label="图片" @click="binType(10)">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 预览图 -->
							<div class="imgList" v-if="list10.length>0" v-for="(item,index) in list10" :key="index">
								<el-image class="dialog-img" :src="item" :preview-src-list="list10"
									:initial-index="index" fit="fill">
								</el-image>
								<p class="dele" @click="dele(index,10)">删除</p>
							</div>
							<el-upload accept=".jpg,.png" :headers="Headers" :action="ActionUrl"
								:on-success="UploadSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
						</div>
					</el-form-item>
					<el-form-item label="附件" @click="binType(11)">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 文件 -->
							<el-upload multiple accept=".pdf,.doc,.docx,.xlm,.zip,.ppt,.xls,.xlsx,.rtf,.word"
								:headers="Headers" :action="ActionUrl" :on-success="UploadSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
							<div class="dialog-up-left">
								<div class="upload-li" v-if="list11.length>0" v-for="(item,index) in list11"
									:key="index">
									<div>{{item.OriginalName}}</div>
									<p @click="dele(index,11)">删除</p>
									<p @click="open(item.url)">下载</p>
								</div>
							</div>
						</div>
					</el-form-item>

					<div class="title">入觉志愿书</div>
					<el-form-item label="图片" @click="binType(12)">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 预览图 -->
							<div class="imgList" v-if="list12.length>0" v-for="(item,index) in list12" :key="index">
								<el-image class="dialog-img" :src="item" :preview-src-list="list12"
									:initial-index="index" fit="fill">
								</el-image>
								<p class="dele" @click="dele(index,12)">删除</p>
							</div>
							<el-upload accept=".jpg,.png" :headers="Headers" :action="ActionUrl"
								:on-success="UploadSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
						</div>
					</el-form-item>
					<el-form-item label="附件" @click="binType(13)">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 文件 -->
							<el-upload multiple accept=".pdf,.doc,.docx,.xlm,.zip,.ppt,.xls,.xlsx,.rtf,.word"
								:headers="Headers" :action="ActionUrl" :on-success="UploadSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
							<div class="dialog-up-left">
								<div class="upload-li" v-if="list13.length>0" v-for="(item,index) in list13"
									:key="index">
									<div>{{item.OriginalName}}</div>
									<p @click="dele(index,13)">删除</p>
									<p @click="open(item.url)">下载</p>
								</div>
							</div>
						</div>
					</el-form-item>

					<div class="title">拟接收预备党员公示</div>
					<el-form-item label="备注">
						<span class="xx"></span>
						<el-input type="textarea" clearable v-model="ruleForm.remarks6" placeholder="请输入备注"></el-input>
					</el-form-item>
					<el-form-item label="图片" @click="binType(14)">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 预览图 -->
							<div class="imgList" v-if="list14.length>0" v-for="(item,index) in list14" :key="index">
								<el-image class="dialog-img" :src="item" :preview-src-list="list14"
									:initial-index="index" fit="fill">
								</el-image>
								<p class="dele" @click="dele(index,14)">删除</p>
							</div>
							<el-upload accept=".jpg,.png" :headers="Headers" :action="ActionUrl"
								:on-success="UploadSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
						</div>
					</el-form-item>
					<el-form-item label="附件" @click="binType(15)">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 文件 -->
							<el-upload multiple accept=".pdf,.doc,.docx,.xlm,.zip,.ppt,.xls,.xlsx,.rtf,.word"
								:headers="Headers" :action="ActionUrl" :on-success="UploadSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
							<div class="dialog-up-left">
								<div class="upload-li" v-if="list15.length>0" v-for="(item,index) in list15"
									:key="index">
									<div>{{item.OriginalName}}</div>
									<p @click="dele(index,15)">删除</p>
									<p @click="open(item.url)">下载</p>
								</div>
							</div>
						</div>
					</el-form-item>

					<div class="title">接收为预备党员的支部党员大会记录</div>
					<el-form-item label="备注">
						<span class="xx"></span>
						<el-input type="textarea" clearable v-model="ruleForm.remarks7" placeholder="请输入备注"></el-input>
					</el-form-item>
					<el-form-item label="图片" @click="binType(16)">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 预览图 -->
							<div class="imgList" v-if="list16.length>0" v-for="(item,index) in list16" :key="index">
								<el-image class="dialog-img" :src="item" :preview-src-list="list16"
									:initial-index="index" fit="fill">
								</el-image>
								<p class="dele" @click="dele(index,16)">删除</p>
							</div>
							<el-upload accept=".jpg,.png" :headers="Headers" :action="ActionUrl"
								:on-success="UploadSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
						</div>
					</el-form-item>
					<el-form-item label="附件" @click="binType(17)">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 文件 -->
							<el-upload multiple accept=".pdf,.doc,.docx,.xlm,.zip,.ppt,.xls,.xlsx,.rtf,.word"
								:headers="Headers" :action="ActionUrl" :on-success="UploadSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
							<div class="dialog-up-left">
								<div class="upload-li" v-if="list17.length>0" v-for="(item,index) in list17"
									:key="index">
									<div>{{item.OriginalName}}</div>
									<p @click="dele(index,17)">删除</p>
								</div>
							</div>
						</div>
					</el-form-item>

					<div class="title">中国共产党预备党员考察簿</div>
					<el-form-item label="备注">
						<span class="xx"></span>
						<el-input type="textarea" clearable v-model="ruleForm.remarks8" placeholder="请输入备注"></el-input>
					</el-form-item>
					<el-form-item label="图片" @click="binType(18)">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 预览图 -->
							<div class="imgList" v-if="list18.length>0" v-for="(item,index) in list18" :key="index">
								<el-image class="dialog-img" :src="item" :preview-src-list="list18"
									:initial-index="index" fit="fill">
								</el-image>
								<p class="dele" @click="dele(index,18)">删除</p>
							</div>
							<el-upload accept=".jpg,.png" :headers="Headers" :action="ActionUrl"
								:on-success="UploadSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
						</div>
					</el-form-item>
					<el-form-item label="附件" @click="binType(19)">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 文件 -->
							<el-upload multiple accept=".pdf,.doc,.docx,.xlm,.zip,.ppt,.xls,.xlsx,.rtf,.word"
								:headers="Headers" :action="ActionUrl" :on-success="UploadSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
							<div class="dialog-up-left">
								<div class="upload-li" v-if="list19.length>0" v-for="(item,index) in list19"
									:key="index">
									<div>{{item.OriginalName}}</div>
									<p @click="dele(index,19)">删除</p>
									<p @click="open(item.url)">下载</p>
								</div>
							</div>
						</div>
					</el-form-item>

					<div class="title">转正申请书</div>
					<el-form-item label="图片" @click="binType(20)">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 预览图 -->
							<div class="imgList" v-if="list20.length>0" v-for="(item,index) in list20" :key="index">
								<el-image class="dialog-img" :src="item" :preview-src-list="list20"
									:initial-index="index" fit="fill">
								</el-image>
								<p class="dele" @click="dele(index,20)">删除</p>
							</div>
							<el-upload accept=".jpg,.png" :headers="Headers" :action="ActionUrl"
								:on-success="UploadSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
						</div>
					</el-form-item>
					<el-form-item label="附件" @click="binType(21)">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 文件 -->
							<el-upload multiple accept=".pdf,.doc,.docx,.xlm,.zip,.ppt,.xls,.xlsx,.rtf,.word"
								:headers="Headers" :action="ActionUrl" :on-success="UploadSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
							<div class="dialog-up-left">
								<div class="upload-li" v-if="list21.length>0" v-for="(item,index) in list21"
									:key="index">
									<div>{{item.OriginalName}}</div>
									<p @click="dele(index,21)">删除</p>
									<p @click="open(item.url)">下载</p>
								</div>
							</div>
						</div>
					</el-form-item>

					<div class="title">预备期思想汇报(每季度1篇)</div>
					<el-form-item label="录入">
						<span class="xx"></span>
						<div class="dialog-up" @click="thought(ruleForm.id)">
							<el-upload disabled>
								<div class="upload">
									<i class="icon iconfont icon-tianjia2"></i>
								</div>
							</el-upload>
						</div>
					</el-form-item>

					<div class="title">预备党员转正公示</div>
					<el-form-item label="备注">
						<span class="xx"></span>
						<el-input type="textarea" clearable v-model="ruleForm.remarks10" placeholder="请输入备注"></el-input>
					</el-form-item>
					<el-form-item label="图片" @click="binType(22)">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 预览图 -->
							<div class="imgList" v-if="list22.length>0" v-for="(item,index) in list22" :key="index">
								<el-image class="dialog-img" :src="item" :preview-src-list="list22"
									:initial-index="index" fit="fill">
								</el-image>
								<p class="dele" @click="dele(index,22)">删除</p>
							</div>
							<el-upload accept=".jpg,.png" :headers="Headers" :action="ActionUrl"
								:on-success="UploadSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
						</div>
					</el-form-item>
					<el-form-item label="附件" @click="binType(23)">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 文件 -->
							<el-upload multiple accept=".pdf,.doc,.docx,.xlm,.zip,.ppt,.xls,.xlsx,.rtf,.word"
								:headers="Headers" :action="ActionUrl" :on-success="UploadSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
							<div class="dialog-up-left">
								<div class="upload-li" v-if="list23.length>0" v-for="(item,index) in list23"
									:key="index">
									<div>{{item.OriginalName}}</div>
									<p @click="dele(index,23)">删除</p>
									<p @click="open(item.url)">下载</p>
								</div>
							</div>
						</div>
					</el-form-item>

					<div class="title">预备党员转正的支部党员大会记录</div>
					<el-form-item label="备注">
						<span class="xx"></span>
						<el-input type="textarea" clearable v-model="ruleForm.remarks11" placeholder="请输入备注"></el-input>
					</el-form-item>
					<el-form-item label="图片" @click="binType(24)">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 预览图 -->
							<div class="imgList" v-if="list24.length>0" v-for="(item,index) in list24" :key="index">
								<el-image class="dialog-img" :src="item" :preview-src-list="list24"
									:initial-index="index" fit="fill">
								</el-image>
								<p class="dele" @click="dele(index,24)">删除</p>
							</div>
							<el-upload accept=".jpg,.png" :headers="Headers" :action="ActionUrl"
								:on-success="UploadSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
						</div>
					</el-form-item>
					<el-form-item label="附件" @click="binType(25)">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 文件 -->
							<el-upload multiple accept=".pdf,.doc,.docx,.xlm,.zip,.ppt,.xls,.xlsx,.rtf,.word"
								:headers="Headers" :action="ActionUrl" :on-success="UploadSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
							<div class="dialog-up-left">
								<div class="upload-li" v-if="list25.length>0" v-for="(item,index) in list25"
									:key="index">
									<div>{{item.OriginalName}}</div>
									<p @click="dele(index,25)">删除</p>
									<p @click="open(item.url)">下载</p>
								</div>
							</div>
						</div>
					</el-form-item>
				</el-form>
			</div>
			<!-- 入党积极分子思想汇报 -->
			<entry ref="entry"></entry>
			<!-- 预备期思想汇报 -->
			<thought ref="thought"></thought>
		</div>
		<div class="dialog-footer" v-if="$store.state.villageId>0">
			<el-button size="mini" @click="$router.go(-1)">取消</el-button>
			<el-button size="mini" class="dialog-btn el-white" type="primary" @click="submitForm">确定
			</el-button>
		</div>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/clique";
	import entry from './entry/index.vue'
	import thought from './thought/index.vue'
	export default {
		components: {
			entry,
			thought
		},
		data() {
			return {
				maxheight: document.documentElement.clientHeight - 180 + 'px',
				tableloading: false,
				Headers: {
					token: ''
				},
				party_member_id: '',
				ActionUrl: '',
				ruleForm: {
					id: '',
					petition: '',
					remark1: '',
					imgpath1: '',
					annex1: '',
					remark2: '',
					imgpath2: '',
					annex2: '',
					a_t_report: '',
					remark3: '',
					imgpath3: '',
					annex3: '',
					family_political_review_material: '',
					no_crime_certificate: '',
					no_i_f_p_certificate: '',
					imgpath4: '',
					annex4: '',
					imgpath5: '',
					annex5: '',
					remarks6: '',
					imgpath6: '',
					annex6: '',
					remarks7: '',
					imgpath7: '',
					annex7: '',
					remarks8: '',
					imgpath8: '',
					annex8: '',
					imgpath9: '',
					annex9: '',
					preliminary_thought_report: '',
					remarks10: '',
					imgpath10: '',
					annex10: '',
					remarks11: '',
					imgpath11: '',
					annex11: '',
					petition_imgpath: ''
				},
				list: [],
				type: 0,
				list1: [],
				list2: [],
				list3: [],
				list4: [],
				list5: [],
				list6: [],
				list7: [],
				list8: [],
				list9: [],
				list10: [],
				list11: [],
				list12: [],
				list13: [],
				list14: [],
				list15: [],
				list16: [],
				list17: [],
				list18: [],
				list19: [],
				list20: [],
				list21: [],
				list22: [],
				list23: [],
				list24: [],
				list25: [],
				list26: []
			}
		},
		mounted() {
			// 上转图片url
			this.Headers.token = sessionStorage.getItem('token')
			this.Headers.appid = sessionStorage.getItem('appid')
			this.Headers.nonce = sessionStorage.getItem('nonce')
			this.Headers.timestamp = sessionStorage.getItem('timestamp')
			this.Headers.sign =sessionStorage.getItem('sign')
			
			this.ActionUrl = sessionStorage.getItem('baseURL') + '/Upload/FormUpload'
		},
		created() {
			this.ruleForm.id = this.$route.query.id
			this.party_member_id = this.$route.query.id
			this.getinfo()
		},
		methods: {
			// 预备期思想汇报
			thought(id) {
				this.$refs.thought.show(id)
			},
			// 入党积极分子思想汇报
			entry(id) {
				this.$refs.entry.show(id)
			},
			// 下载
			open(url) {
				window.location.href = url
			},
			// 类型
			binType(type) {
				this.type = type
			},
			// 删除
			dele(index, type) {
				if (type == 0) {
					this.list.splice(index, 1)
				}
				if (type == 1) {
					this.list1.splice(index, 1)
				}
				if (type == 2) {
					this.list2.splice(index, 1)
				}
				if (type == 3) {
					this.list3.splice(index, 1)
				}
				if (type == 4) {
					this.list4.splice(index, 1)
				}
				if (type == 5) {
					this.list5.splice(index, 1)
				}
				if (type == 6) {
					this.list6.splice(index, 1)
				}
				if (type == 7) {
					this.list7.splice(index, 1)
				}
				if (type == 8) {
					this.list8.splice(index, 1)
				}
				if (type == 9) {
					this.list9.splice(index, 1)
				}

				if (type == 10) {
					this.list10.splice(index, 1)
				}
				if (type == 11) {
					this.list11.splice(index, 1)
				}
				if (type == 12) {
					this.list12.splice(index, 1)
				}
				if (type == 13) {
					this.list13.splice(index, 1)
				}
				if (type == 14) {
					this.list14.splice(index, 1)
				}
				if (type == 15) {
					this.list15.splice(index, 1)
				}
				if (type == 16) {
					this.list16.splice(index, 1)
				}
				if (type == 17) {
					this.list17.splice(index, 1)
				}
				if (type == 18) {
					this.list18.splice(index, 1)
				}
				if (type == 19) {
					this.list19.splice(index, 1)
				}
				if (type == 20) {
					this.list20.splice(index, 1)
				}
				if (type == 21) {
					this.list21.splice(index, 1)
				}
				if (type == 22) {
					this.list22.splice(index, 1)
				}
				if (type == 23) {
					this.list23.splice(index, 1)
				}
				if (type == 24) {
					this.list24.splice(index, 1)
				}
				if (type == 25) {
					this.list25.splice(index, 1)
				}
				if (type == 26) {
					this.list26.splice(index, 1)
				}
			},
			// 上传
			UploadSuccess(res, file) {
				if (this.type == 0) {
					this.list.push(res.data)
				}
				if (this.type == 1) {
					this.list1.push(res.data.url)
				}
				if (this.type == 2) {
					this.list2.push(res.data)
				}
				if (this.type == 3) {
					this.list3.push(res.data.url)
				}
				if (this.type == 4) {
					this.list4.push(res.data)
				}
				if (this.type == 5) {
					this.list5.push(res.data.url)
				}
				if (this.type == 6) {
					this.list6.push(res.data)
				}

				if (this.type == 7) {
					this.list7.push(res.data)
				}
				if (this.type == 8) {
					this.list8.push(res.data)
				}
				if (this.type == 9) {
					this.list9.push(res.data)
				}
				if (this.type == 10) {
					this.list10.push(res.data.url)
				}
				if (this.type == 11) {
					this.list11.push(res.data)
				}
				if (this.type == 12) {
					this.list12.push(res.data.url)
				}
				if (this.type == 13) {
					this.list13.push(res.data)
				}
				if (this.type == 14) {
					this.list14.push(res.data.url)
				}
				if (this.type == 15) {
					this.list15.push(res.data)
				}
				if (this.type == 16) {
					this.list16.push(res.data.url)
				}
				if (this.type == 17) {
					this.list17.push(res.data)
				}
				if (this.type == 18) {
					this.list18.push(res.data.url)
				}
				if (this.type == 19) {
					this.list19.push(res.data)
				}
				if (this.type == 20) {
					this.list20.push(res.data.url)
				}
				if (this.type == 21) {
					this.list21.push(res.data)
				}
				if (this.type == 22) {
					this.list22.push(res.data.url)
				}
				if (this.type == 23) {
					this.list23.push(res.data)
				}

				if (this.type == 24) {
					this.list24.push(res.data.url)
				}
				if (this.type == 25) {
					this.list25.push(res.data)
				}
				if (this.type == 26) {
					this.list26.push(res.data.url)
				}

			},
			// 新增/编辑
			submitForm() {
				this.ruleForm.petition = this.list.length > 0 ? JSON.stringify(this.list) : ''
				this.ruleForm.imgpath1 = this.list1.join(",")
				this.ruleForm.annex1 = this.list2.length > 0 ? JSON.stringify(this.list2) : ''
				this.ruleForm.imgpath2 = this.list3.join(",")
				this.ruleForm.annex2 = this.list4.length > 0 ? JSON.stringify(this.list4) : ''
				this.ruleForm.imgpath3 = this.list5.join(",")
				this.ruleForm.annex3 = this.list6.length > 0 ? JSON.stringify(this.list6) : ''
				this.ruleForm.family_political_review_material = this.list7.length > 0 ? JSON.stringify(this.list7) : ''
				this.ruleForm.no_crime_certificate = this.list8.length > 0 ? JSON.stringify(this.list8) : ''
				this.ruleForm.no_i_f_p_certificate = this.list9.length > 0 ? JSON.stringify(this.list9) : ''
				this.ruleForm.imgpath4 = this.list10.join(",")
				this.ruleForm.annex4 = this.list11.length > 0 ? JSON.stringify(this.list11) : ''
				this.ruleForm.imgpath5 = this.list12.join(",")
				this.ruleForm.annex5 = this.list13.length > 0 ? JSON.stringify(this.list13) : ''
				this.ruleForm.imgpath6 = this.list14.join(",")
				this.ruleForm.annex6 = this.list15.length > 0 ? JSON.stringify(this.list15) : ''
				this.ruleForm.imgpath7 = this.list16.join(",")
				this.ruleForm.annex7 = this.list17.length > 0 ? JSON.stringify(this.list17) : ''
				this.ruleForm.imgpath8 = this.list18.join(",")
				this.ruleForm.annex8 = this.list19.length > 0 ? JSON.stringify(this.list19) : ''
				this.ruleForm.imgpath9 = this.list20.join(",")
				this.ruleForm.annex9 = this.list21.length > 0 ? JSON.stringify(this.list21) : ''
				this.ruleForm.imgpath10 = this.list22.join(",")
				this.ruleForm.annex10 = this.list23.length > 0 ? JSON.stringify(this.list23) : ''
				this.ruleForm.imgpath11 = this.list24.join(",")
				this.ruleForm.annex11 = this.list25.length > 0 ? JSON.stringify(this.list25) : ''
				this.ruleForm.petition_imgpath = this.list26.join(",")

				this.$http.post(api.infoupdate, this.ruleForm)
					.then((res) => {
						if (res.code == 200) {
							ElMessage({
								type: 'success',
								message: '更新成功',
							})
							this.getinfo()
						}
					})
			},
			// 获取信息
			getinfo() {
				this.tableloading = true
				this.$http.post(api.getread, {
						party_member_id: this.party_member_id
					})
					.then((res) => {
						if (res.code == 200) {
							this.ruleForm = res.data
							this.list = this.ruleForm.petition ? JSON.parse(res.data.petition) : []
							this.list1 = this.ruleForm.imgpath1 ? this.ruleForm.imgpath1.split(",") : []
							this.list2 = this.ruleForm.annex1 ? JSON.parse(this.ruleForm.annex1) : []
							this.list3 = this.ruleForm.imgpath2 ? this.ruleForm.imgpath2.split(",") : []
							this.list4 = this.ruleForm.annex2 ? JSON.parse(this.ruleForm.annex2) : []
							this.list5 = this.ruleForm.imgpath3 ? this.ruleForm.imgpath3.split(",") : []
							this.list6 = this.ruleForm.annex3 ? JSON.parse(this.ruleForm.annex3) : []
							this.list7 = this.ruleForm.family_political_review_material ? JSON.parse(this.ruleForm
								.family_political_review_material) : []
							this.list8 = this.ruleForm.no_crime_certificate ? JSON.parse(this.ruleForm
								.no_crime_certificate) : []
							this.list9 = this.ruleForm.no_i_f_p_certificate ? JSON.parse(this.ruleForm
								.no_i_f_p_certificate) : []
							this.list10 = this.ruleForm.imgpath4 ? this.ruleForm.imgpath4.split(",") : []
							this.list11 = this.ruleForm.annex4 ? JSON.parse(this.ruleForm.annex4) : []
							this.list12 = this.ruleForm.imgpath5 ? this.ruleForm.imgpath5.split(",") : []
							this.list13 = this.ruleForm.annex5 ? JSON.parse(this.ruleForm.annex5) : []
							this.list14 = this.ruleForm.imgpath6 ? this.ruleForm.imgpath6.split(",") : []
							this.list15 = this.ruleForm.annex6 ? JSON.parse(this.ruleForm.annex6) : []
							this.list16 = this.ruleForm.imgpath7 ? this.ruleForm.imgpath7.split(",") : []
							this.list17 = this.ruleForm.annex7 ? JSON.parse(this.ruleForm.annex7) : []
							this.list18 = this.ruleForm.imgpath8 ? this.ruleForm.imgpath8.split(",") : []
							this.list19 = this.ruleForm.annex8 ? JSON.parse(this.ruleForm.annex8) : []
							this.list20 = this.ruleForm.imgpath9 ? this.ruleForm.imgpath9.split(",") : []
							this.list21 = this.ruleForm.annex9 ? JSON.parse(this.ruleForm.annex9) : []
							this.list22 = this.ruleForm.imgpath10 ? this.ruleForm.imgpath10.split(",") : []
							this.list23 = this.ruleForm.annex10 ? JSON.parse(this.ruleForm.annex10) : []
							this.list24 = this.ruleForm.imgpath11 ? this.ruleForm.imgpath11.split(",") : [],
								this.list26 = this.ruleForm.petition_imgpath ? this.ruleForm.petition_imgpath.split(
									",") : [],
								this.list25 = this.ruleForm.annex11 ? JSON.parse(this.ruleForm.annex11) : []
							this.tableloading = false
						}
					})
			}
		}
	}
</script>
<style scoped lang="scss">
	.imgList {
		height: 80px;
	}

	.title {
		font-size: 16px;
		padding-bottom: 20px;
	}

	.dialog-footer {
		background-color: #fff;
		padding: 20px;
	}

	.info-bg {
		background-color: #fff;
		overflow-y: scroll;
		padding: 20px;
		height: 100%;

	}

	.Draggable {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}

	.dialog-up {
		.top {
			font-size: 12px;
			position: absolute;
			top: 0;
			height: 20px;
			line-height: 20px;
			right: 0;

			i {
				padding: 3px;
				cursor: pointer;
				font-size: 14px;
				margin-left: 5px;
				color: #FFFFFF;
				background: rgba(0, 0, 0, 0.5);
			}
		}
	}

	.dialog-up-left {
		float: left;
		margin-left: 20px;
	}

	.upload-li {
		font-size: 12px;
		display: flex;
		height: 20px;
		line-height: 20px;
	}

	.upload-li p {
		cursor: pointer;
		color: #999999;
		margin-left: 20px;
	}

	.upload-li p:hover {
		color: #00ad79;
	}

	.dialog-title {
		margin: 40px 0;
		font-size: 16px;
	}

	.dialog-img-box {
		cursor: move;
		position: relative;
		height: 80px;
		overflow: hidden;
		width: 80px;
		margin-right: 10px;
		border: 1px solid #dcdde0;
		border-radius: 4px;
		text-align: center;
		margin-bottom: 10px;
	}

	.dialog-img-box p {
		font-size: 12px;
		width: 80px;
		position: absolute;
		bottom: 0;
		height: 20px;
		line-height: 20px;
		left: 0;
		text-align: center;
		background: rgba(0, 0, 0, 0.5);
		color: #FFFFFF;
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		line-clamp: 1;
		-webkit-box-orient: vertical;
	}
</style>